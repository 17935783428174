<template>
  <v-card :loading="loading" class="rounded-0 mx-auto " style="min-height: 550px">
    <v-img :src="item.cover.media.url" style="height: 275px"></v-img>
    <v-card-title>{{ item.name }}</v-card-title>
    <v-card-text style="height: 150px">{{ item.description }}</v-card-text>
    <v-card-actions style="display: block">
<v-btn-toggle style="display: flex; align-items: center">
      <v-btn elevation="0" block dark
             large
             @click="hidden = !hidden;"
             tile class="rounded-0"  color="primary" style=" min-width: 0px!important;">
        {{ hidden ? 'Aus Sortiment entfernen' : 'Zum Sortiment hinzufügen' }}
      </v-btn>
</v-btn-toggle>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["item"],

  data: () => ({
    hidden: false,
  })



}

</script>
