<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-row>
        <v-col cols="6" md="3" lg="3" v-for="product in productList" :key="product.productNumber">
          <ProductCard :item="product" />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductCard from "@/modules/shop/components/ProductCard";

export default {
  components: {ProductCard},
  data: () => ({}),

  mounted() {
    this.init();
  },

  computed: {
    ...mapGetters("product", ["products"]),

    productList: function() {
      return this.products?.elements;
    }
  },

  methods: {
    ...mapActions("product", ["list"]),

    init() {
      this.list(
          {
            filter: [
              {
                type: "equals",
                field: "active",
                value: true
              }
            ],
            includes: {
              product: [
                "productNumber",
                "name",
                "description",
                "categories",
                "cover"
              ]
            }
          }
      );
    }
  }
}
</script>
